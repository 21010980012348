import React from "react";
import App from "../components/app";
import Layout from "../layout/layout";

const IndexPage = () => {
  return (
    <Layout>
      <App />
    </Layout>
  )
}

export default IndexPage
